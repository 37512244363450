@import "./base";
// Components from other files
@import "./jumbotron";
@import "./table";

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $gray-100;
}

.main {
  background-color: $gray-100;
}

.assignment-by-ul {
  padding: 0;
  padding-left: 20px;

  list-style: none;
  // counter-reset: my-awesome-counter;
  li::before {
    content: "•";
    font-size: 1.3rem;
    margin-right: 10px;
  }
}

.cursor-hon-allowed {
  cursor: not-allowed;

  :hover {
    cursor: not-allowed;
  }
}

.hover-cursor--pointer {
  :hover {
    cursor: pointer;
  }
}

.breadcrumb-item {
  a {
    font-weight: bolder;
    color: $gray-600;
  }
}
.breadcrumb-item.active {
  a {
    text-decoration: none;
  }
}

.icon--action {
  color: $black;

  &:hover {
    color: $gray-600;
  }
}

.gtins .table {
  table-layout: fixed;
}

.grais .table {
  table-layout: fixed;
}

.grai-series .table thead th:first-of-type {
  margin-top: auto;
}

.status {
  font-size: 2rem;
  line-height: 1rem;
  color: $gray-500;

  &--online {
    color: $green;
  }

  &--offline {
    color: $red;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.single-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hover-white-space-normal:hover {
  white-space: normal;
}

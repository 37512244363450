.table-component {
  padding: 20px;
  background-color: $white;
  border-radius: 10px;
}

.table {
  th {
    vertical-align: middle;
    line-height: 1.3rem;
    padding: 20px 20px;
    text-align: center;
  }

  td {
    padding: 10px 20px;
    text-align: center;
  }

  thead > tr > th:last-of-type {
    text-align: center;
  }

  tbody > tr:last-of-type td {
    padding-bottom: 20px;
  }

  tbody > tr:first-of-type td {
    padding-top: 20px;
  }

  tfoot > tr > td {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
